<template>
  <span >
    <!--<template v-if="columnName == 'operator'">

      
      <template v-if="service_tv && service_inet && service_tv.operator == service_inet.operator">
        {{ service_tv.operator && operators.find(x => x.id == service_tv.operator).name }}
      </template>
      <template v-else>
        <span class="d-flex justify-space-between" v-if="service_tv" >
          <span>{{ service_tv.operator && operators.find(x => x.id == service_tv.operator).name }}</span>
        </span>
        <span class="d-flex justify-space-between" v-if="service_inet_billing" >
          <span>{{ service_inet_billing.operator && operators.find(x => x.id == service_inet_billing.operator).name }}</span>
        </span>
      </template>
    </template>
  -->
    <span v-if="columnName == 'status'" class="" :title="titleConnect">
      <span class="d-flex justify-space-between">  
        <span class="d-flex justify-start" :class="{'mr-2': !checkDoubleTarif}" v-if="service_tv" >
          <v-icon   :color="service_tv.status ? 'green' : 'error'">mdi-television</v-icon> <!--television-box-->
        </span>
        <i v-else />
        <v-icon x-small class="px-1" v-if="checkDoubleTarif">mdi-plus</v-icon>
        <span class="d-flex justify-end" :class="{'ml-2': !checkDoubleTarif}" v-if="service_inet_billing" >
          <template v-if="item.billing_data && item.billing_data.online && item.billing_data.user_service_status">
            <v-icon  color="light-blue darken-1">mdi-web</v-icon>
          </template>
          <template v-else-if="item.billing_data && item.billing_data.online">
            <v-badge
            color="light-blue darken-1"
              dot
              overlap
            >
            <v-icon  color="error">mdi-web</v-icon>
            </v-badge>
          </template>
          <template v-else>
            <v-icon  :color="item.billing_data && item.billing_data.user_service_status ? 'grey' : 'error'">mdi-web</v-icon>
          </template>
        </span>
      </span>
    </span>

    <span v-else-if="columnName == 'balance'" :title=" 'Особовий рахунок: ' + (service_tv && ('\n\nТБ: ' + item.id) || '') + ( service_inet && item.billing_data && ('\n\nІнтернет: ' + item.billing_data.user_id) || '')">
        
      <span class="d-flex" v-if="service_tv" :class="service_tv.credit != 0 ? 'justify-space-between' : 'justify-end'">
        <v-icon v-if="service_tv.credit != 0" :title="`Кредит: ${service_tv.credit} грн.`" x-small style="vertical-align: baseline;" class="mr-1"  color="orange">mdi-credit-card</v-icon>
        <span style="white-space: nowrap;" >{{ service_tv.balance }} <v-icon style="vertical-align: baseline;" x-small  color="grey">mdi-television</v-icon></span>
      </span>
      <i v-else />
      <span class="d-flex " :class="item.billing_data.discount > 0 || item.billing_data.credit > 0 ? 'justify-space-between' : 'justify-end'" v-if="service_inet_billing" >
        
        <v-icon v-if="item.billing_data.credit != 0 " :title="`Кредит: ${item.billing_data.credit} грн.`" x-small style="vertical-align: baseline;" class="mr-1"  color="orange">mdi-credit-card</v-icon>
        <v-icon v-if="item.billing_data.discount != 0" :title="`Знижка: ${item.billing_data.discount}%`" x-small style="vertical-align: baseline;" class="mr-2"  color="primary lighten-2">mdi-percent-circle</v-icon>
        
        <span style="white-space: nowrap;" :class="{'grey--text': checkDoubleTarif, 'text--darken-2':checkDoubleTarif && $vuetify.theme.dark}" class="ml-auto">{{ item.billing_data.user_current_balance }} <v-icon style="vertical-align: baseline;" x-small  color="grey" :class="{'text--darken-2': checkDoubleTarif && $vuetify.theme.dark}">mdi-web</v-icon></span>
      </span>
    </span>

    <template v-else-if="columnName == 'tarif'">
      <template v-if="checkDoubleTarif">
        <span>{{ service_tv.tarif && service_tv.tarif.name}}</span>
      </template>
      <template v-else>
        <span class="d-flex justify-space-between" v-if="service_tv" >
          <span>{{ service_tv.tarif &&  service_tv.tarif.name}}</span>
        </span>
        <span class="d-flex justify-space-between" v-if="service_inet_billing" >
          <span>{{ tarifInet }}</span>
        </span>
      </template>
    </template>

  </span>
</template>

<script>
export default {
    props: ['columnName', 'item', 'operators', "rates"],
    data: () => ({

    }),
    computed: {
      service_tv() {
        //console.log(this.item?.user_service?.find(x => x.service == 1)?.credit)
        return this.item?.user_service?.find(x => x.service == 1)
      },
      service_inet() {
        return this.item?.user_service?.find(x => x.service == 2)
      },
      service_inet_billing() {
        return this.item?.billing_data?.user_service || false
      },
      tarifInet(){
        try {
          return this.item?.billing_data?.user_current_tariff && this.rates.find(x => x.id == this.item.billing_data.user_current_tariff).name
        }catch{
          //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!Помилка отримання тарифу!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1", this.item)
          return ''
        } 
      },
      checkDoubleService(){
        return this.service_tv && this.service_inet_billing && true || false
      },
      checkDoubleTarif(){
        return this.service_tv && this.service_inet_billing && this.service_tv.tarif.id == this.item?.billing_data?.user_current_tariff
      },
      titleConnect(){
        let caller_id = ''
        let type_connect = this.item?.billing_data?.caller?.type_connect
        if(type_connect == 'IPoE'){
          if(this.item.billing_data.caller.onu_number) type_connect = "IPoE (xPON)"
          caller_id = `${this.item.billing_data.caller.mac_switch + '/' + this.item.billing_data.caller.vlan + '/' + this.item.billing_data.caller.port + '/' + this.item.billing_data.caller.mac_abonent}`
        }else if(type_connect == 'PPPoE'){
          caller_id = `${this.item.billing_data.caller.vlan} / ${this.item.billing_data.caller.mac_abonent}`
        }
        return this.service_inet && `Логін: ${this.item.username}\n\nТип підключення: ${type_connect}\n\nЧас активності: ${this.item.billing_data.session_time || ''}\n\nПристрій: ${this.item?.billing_data?.hostname || ''}\n\n${caller_id}` || ''
      }
      
    }
    
}
</script>