<template>
  <div class="">
    
    <transition name="page" mode="out-in">
      <keep-alive>
        <router-view v-if="$route.name == 'abonent_info'"></router-view>

        <AbonentsTable :search="search"/>
    </keep-alive>
    
    
    </transition>
  </div>
</template>

<script>
import AbonentsTable from '@/components/Abonents/AbonentsTable.vue'
import '@/components/Tools'
export default {
  name: 'Abonents',
  components: {
    AbonentsTable
  },
  data: () => ({
    search: ''
  }),
  mounted (){
    //  
  },
  methods: {
    startSearch: function(search){
      this.search = search 
    },
    changeTableComponent(component){
      this.componentTable = component
    }
  }
}
</script>
