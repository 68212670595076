const searchGroupData = [
    { title: 'Автоматично', prefix: ' ', name: '', id: 0 },
    { title: 'По Вулиці', prefix: 'по вулиці: ', name: 'street_str', id: 1 },
    { title: 'По П.І.Б.', prefix: 'по ПІБ: ', name: 'full_name', id: 2 },
    { title: ' По Організації', prefix: 'по організації: ', name: 'organization', id: 3 },
    { title: 'По Логіну', prefix: 'по логіну:', name: 'username', id: 4 },
    //{ title: 'Телефону', prefix: 'по телефону: ', name: 'phone', id: 5 },
    //{ title: 'Рахунку Інтернет', prefix: 'по рахунку І: ', name: 'inet_id', id: 6 },
    //{ title: 'Рахунку ТБ', prefix: 'по рахунку ТБ: ', name: 'id', id: 7 },
]
export default searchGroupData